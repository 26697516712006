<template>
  <div class="service-copy-message">
    <el-form label-width="100px">
      <el-form-item label="公众号">
        <el-select
          v-model="accountChannelIds"
          style="width: 80%"
          placeholder="请选择"
          multiple
          clearable
          filterable
        >
          <el-option
            v-for="item in account"
            :key="item.id"
            :value="item.id"
            :label="item.channelName"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <span class="copy-numbers"
          >复制次数: <i class="iconfont icon-tishi" title="单次复制最多5次"></i
        ></span>
        <span
          ><el-input-number
            v-model="copyNum"
            placeholder=""
            :min="1"
            :max="5"
          ></el-input-number
        ></span>
      </el-form-item>
    </el-form>
    <span class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button @click="handleSubmit" type="primary" :loading="submitLoading"
        >确 定</el-button
      >
    </span>
  </div>
</template>

<script>
import { copyHourMsg } from '@/api/service'

export default {
  name: 'CopyMesg',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    fixedIds: {
      type: Array,
      default: () => []
    },
    accountList: Array,
    accountId: Number
  },
  data () {
    return {
      accountChannelIds: [],
      account: [],
      submitLoading: false,
      isCopyTime: false,
      copyNum: 1
    }
  },
  watch: {
    show: {
      handler (newVal) {
        if (newVal) {
          const defaultChannel = this.accountList.find(
            (item) => item.id === this.accountId
          )
          this.account = this.accountList.filter(
            (item) => item.platform === defaultChannel.platform
          )
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit () {
      const { accountChannelIds } = this
      if (accountChannelIds && accountChannelIds.length) {
        this.submitLoading = true
        copyHourMsg(this.fixedIds.join(), {
          accountChannelIds,
          copyNum: this.copyNum
        })
          .then(() => {
            this.$message.success('复制成功')
            this.$emit('refresh')
          })
          .finally(() => {
            this.submitLoading = false
            this.handleClose()
          })
      } else {
        this.$message.error('请选择公众号')
      }
    },
    handleClose () {
      this.accountChannelIds = []
      this.$emit('close')
      this.copyNum = 1
    },
    switchChangeHandler (e) {
      this.isCopyTime = e
    }
  }
}
</script>

<style scoped>
.copy-numbers {
  color: #1f2d3d;
  margin-right: 10px;
}
.isCopyTime {
  color: #1f2d3d;
  font-size: 14px;
}
.isCopyTimeSwitch {
  margin-left: 10px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  margin-top: 40px;
  text-align: center;
}
</style>
