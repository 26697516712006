<template>
  <el-dialog
    title="客服消息详情"
    :visible.sync="show"
    center
    width="850px"
    top="5vh"
    :before-close="handleClose"
  >
    <div class="message-hour-detail">
      <el-form
        label-width="120px"
        class="form-container"
        v-loading="formLoading"
      >
        <el-form-item label="公众号：">
          <span>{{ form.channelName }}</span>
        </el-form-item>
        <el-form-item label="备注：">
          <span>{{ form.remark || "无" }}</span>
        </el-form-item>
        <el-form-item label="发送分钟：">
          <span>{{ form.hours }}</span>
        </el-form-item>
        <el-form-item label="消息列表：">
          <div v-for="(item, index) in messageList" :key="index" class="item">
            <el-form v-if="item.msgtype === 'text'" label-width="80px">
              <el-form-item label="消息类型">
                <el-tag type="info">文本</el-tag>
              </el-form-item>
              <el-form-item label="消息内容">
                <p style="margin-left: 10px">{{ item.textStr }}</p>
              </el-form-item>
            </el-form>
            <el-form v-if="item.msgtype === 'news'" label-width="80px">
              <el-form-item label="消息类型">
                <el-tag>图文</el-tag>
              </el-form-item>
              <el-form-item label="链接">
                <span style="margin-right: 10px">{{ item.novelName }}</span>
              </el-form-item>
              <el-form-item>
                <div class="example">
                  <div class="card">
                    <p class="mesg-title">{{ item.title || "消息标题" }}</p>
                    <div class="detail">
                      <p>{{ item.description || "描述文字" }}</p>
                      <template>
                        <img :src="item.picurl" alt="" v-if="item.picurl" />
                        <div class="image-slot" v-else>
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <el-form v-if="item.msgtype === 'voice'" label-width="80px">
              <el-form-item label="消息类型">
                <el-tag>音频</el-tag>
              </el-form-item>
              <p style="margin-left: 10px">
                {{ item.selectVoice.title || "无" }}
              </p>
            </el-form>
            <el-form v-if="item.msgtype === 'image'" label-width="80px">
              <el-form-item label="消息类型">
                <el-tag>图片</el-tag>
              </el-form-item>
              <template v-if="item.selectImage">
                <span v-if="item.selectImage.title.includes('{')">
                  <p v-if="form.msgType === 'image'">
                    {{ JSON.parse(item.selectImage.title).title || "无" }}
                  </p>
                  <img
                    style="max-width: 200px; max-height: 200px"
                    :src="JSON.parse(item.selectImage.title).url"
                    alt=""
                  />
                </span>
                <span v-else>
                  <p>{{ item.selectImage.title || "无" }}</p>
                </span>
              </template>
              <!-- <p style="margin-left: 10px">{{ selectImage.title || "无" }}</p> -->
            </el-form>
            <el-form
              v-if="item.msgtype === 'miniprogrampage'"
              label-width="80px"
            >
              <el-form-item label="消息类型">
                <el-tag>小程序卡片</el-tag>
              </el-form-item>
              <div v-if="item.selectmini" style="padding: 10px">
                <img
                  style="max-width: 100px; max-height: 100px"
                  :src="item.selectmini.novelName"
                  alt=""
                />
                <p>
                  <span>标题：</span>
                  <span>{{ item.selectmini.title }}</span>
                </p>
                <p>
                  <span>appid：</span>
                  <span>{{ item.selectmini.appid }}</span>
                </p>
                <p>
                  <span>页面路径：</span>
                  <span>{{ item.selectmini.pagepath }}</span>
                </p>
              </div>
            </el-form>
          </div>
        </el-form-item>
        <!-- 新增标签筛选 -->
        <el-form-item label="接收用户">
          <el-tag v-if="form.sendAll === 1" type="info">全部用户</el-tag>
          <el-tag v-if="form.sendAll === 0">标签用户</el-tag>
        </el-form-item>
        <el-form-item
          label="关注时间"
          v-if="form.sendAll === 0 && (form.startDate || form.endDate)"
        >
          {{ form.startDate }} - {{ form.endDate }}
        </el-form-item>
        <el-form-item label="充值情况" v-if="form.sendAll === 0">
          <span v-if="form.rechargeType === 0">不限</span>
          <span v-else-if="form.rechargeType === 1"> 未充值</span>
          <span v-else>已充值</span>
        </el-form-item>
        <el-form-item
          label="充值间隔"
          v-if="form.sendAll === 0 && form.rechargeType === 2"
        >
          <span
            v-if="!form.rechargeTimeSpaceStart && !form.rechargeTimeSpaceEnd"
            >无</span
          >
          <span v-else-if="!form.rechargeTimeSpaceEnd">
            {{ form.rechargeTimeSpaceStart }} 天以上
          </span>
          <span v-else-if="!form.rechargeTimeSpaceStart">
            {{ form.rechargeTimeSpaceEnd }} 天以下
          </span>
          <span v-else>
            {{ form.rechargeTimeSpaceStart }} - {{ form.rechargeTimeSpaceEnd }}
            <span style="margin-left: 20px">天</span>
          </span>
        </el-form-item>
        <el-form-item
          label="剩余书币"
          v-if="form.sendAll === 0 && (form.maxKanDian || form.minKanDian)"
        >
          <span
            style="margin-right: 50px"
            v-if="form.maxKanDian || form.minKanDian"
            >{{ form.minKanDian ? form.minKanDian : "" }} -
            {{ form.maxKanDian ? form.maxKanDian : "" }}</span
          >
          <span v-else style="margin-right: 50px">无</span>
        </el-form-item>
        <el-form-item
          label="累计充值"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span v-if="!form.minTotalRecharge && !form.maxTotalRecharge">
            无
          </span>
          <span v-else style="margin-right: 50px"
            >{{ form.minTotalRecharge }} - {{ form.maxTotalRecharge }}
            <span style="margin-left: 20px">元</span></span
          >
        </el-form-item>
        <el-form-item
          label="最后充值"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span v-if="!form.minLastRechargeAmt && !form.maxLastRechargeAmt">
            无
          </span>
          <span v-else style="margin-right: 50px"
            >{{ form.minLastRechargeAmt }} - {{ form.maxLastRechargeAmt }}
            <span style="margin-left: 20px">元</span></span
          >
        </el-form-item>
        <el-form-item
          label="充值次数"
          v-if="
            form.rechargeType === 2 &&
            form.sendAll === 0 &&
            (form.minRechargeCount || form.maxRechargeCount)
          "
        >
          <span v-if="!form.minRechargeCount && !form.maxRechargeCount">-</span>
          <template v-else>
            <span
              style="margin-right: 50px"
              v-if="!form.minRechargeCount && !form.maxRechargeCount"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.minRechargeCount }} - {{ form.maxRechargeCount }}
              <span style="margin-left: 20px">次</span></span
            >
          </template>
        </el-form-item>
        <el-form-item
          label="近7日充值"
          v-if="
            form.rechargeType === 2 &&
            form.sendAll === 0 &&
            (form.maxSevenRecharge || form.minSevenRecharge)
          "
        >
          <span v-if="!form.minSevenRecharge && !form.maxSevenRecharge">-</span>
          <template v-else>
            <span
              style="margin-right: 50px"
              v-if="!form.minSevenRecharge && !form.maxSevenRecharge"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.minSevenRecharge }} - {{ form.maxSevenRecharge }}
              <span style="margin-left: 20px">元</span></span
            >
          </template>
        </el-form-item>
        <el-form-item
          label="是否vip"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span v-if="form.isVip === 0">否</span>
          <span v-if="form.isVip === 1">是</span>
          <span v-if="form.isVip === 2">不限</span>
        </el-form-item>
        <el-form-item
          label="连续付费用户"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <span>{{ form.continuousRecharge ? "是" : "不限" }}</span>
        </el-form-item>
        <el-form-item
          label="RFM"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <el-tag v-if="!form.rfm" type="info">不限</el-tag>
          <el-tag v-if="form.rfm && form.rfm.includes('0')" type="info"
            >不限</el-tag
          >
          <el-tag
            v-if="form.rfm && form.rfm.includes('1')"
            class="margin-right-ten"
            type="success"
            >A类</el-tag
          >
          <el-tag
            v-if="form.rfm && form.rfm.includes('2')"
            class="margin-right-ten"
            type="warning"
            >B类</el-tag
          >
          <el-tag
            v-if="form.rfm && form.rfm.includes('3')"
            class="margin-right-ten"
            type="danger"
            >C类</el-tag
          >
        </el-form-item>
        <el-form-item
          label="优惠敏感度"
          v-if="form.rechargeType === 2 && form.sendAll === 0"
        >
          <el-tag v-if="form.couponLevel === 0" type="info">不限</el-tag>
          <el-tag v-if="form.couponLevel === 1">1级</el-tag>
          <el-tag v-if="form.couponLevel === 2">2级</el-tag>
          <el-tag v-if="form.couponLevel === 3">3级</el-tag>
          <el-tag v-if="form.couponLevel === 4">4级</el-tag>
          <el-tag v-if="form.couponLevel === 5">5级</el-tag>
        </el-form-item>
        <el-form-item label="性别" v-if="form.sendAll === 0">
          <span v-if="form.sex === 0">不限</span>
          <span v-else-if="form.sex === 1">男</span>
          <span v-else>女</span>
        </el-form-item>
        <el-form-item
          label="所在地区"
          v-if="form.sendAll === 0 && (form.city || form.province)"
        >
          <span v-if="!form.province && !form.city">-</span>
          <template v-else>
            <span style="margin-right: 50px" v-if="!form.province && !form.city"
              >无</span
            >
            <span style="margin-right: 50px" v-else>
              {{ form.province }} - {{ form.city }}
              <span style="margin-left: 20px"></span
            ></span>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import { getHourMessageDetail } from "../../../api/service";

export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Array,
      default: () => [],
    },
    info: Object,
  },
  data() {
    return {
      form: {},
      messageList: [],
      formLoading: false,
      selectVoice: {},
      selectImage: {},
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal && this.info) {
          this.initData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    initData() {
      this.formLoading = true;
      getHourMessageDetail(this.info.id)
        .then((res) => {
          const { fixedContentsList, ...other } = res;
          this.form = other;
          this.messageList = fixedContentsList.map((item) => {
            const { msgtype, content, novelName } = item;
            let data = {};
            const contentObj = JSON.parse(content);
            if (msgtype === "text") {
              data.textStr = contentObj.content;
            } else if (msgtype === "news") {
              data = { ...contentObj.articles[0], novelName };
            } else if (msgtype === "voice") {
              data.selectVoice = {
                mediaId: contentObj.media_id,
                title: novelName,
              };
            } else if (msgtype === "image") {
              data.selectImage = {
                mediaId: contentObj.media_id,
                title: novelName,
              };
            } else if (msgtype === "miniprogrampage") {
              data.selectmini = {
                novelName: novelName,
                title: contentObj.title,
                appid: contentObj.appid,
                pagepath: contentObj.pagepath,
              };
            }
            data.msgtype = msgtype;
            return data;
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.message-hour-detail {
  box-sizing: border-box;
  padding: 20px;
}
.form-container {
  max-height: 800px;
  overflow: auto;
  padding-right: 10px;
}
.item {
  width: 600px;
  border-radius: 8px;
  border: 1px solid #ececec;
  margin-bottom: 10px;
  padding-right: 20px;
  padding-top: 10px;
}
.example {
  width: 375px;
  background-color: #f1f1f1;
  padding: 10px;
}

.card {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mesg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: bold;
  line-height: 20px;
}

.detail {
  display: flex;
  align-items: center;
}

.detail p {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.detail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
</style>
