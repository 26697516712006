<template>
  <div
    class="content-cont"
    :style="{ borderColor: active ? '#409EFF' : '#ececec' }"
    @click="$emit('check')"
  >
    <el-radio v-model="form.msgtype" label="text">文本</el-radio>
    <el-radio v-model="form.msgtype" label="news">图文</el-radio>
    <el-radio v-model="form.msgtype" label="voice">音频</el-radio>
    <el-radio v-model="form.msgtype" label="image">图片</el-radio>
    <el-radio v-model="form.msgtype" label="miniprogrampage"
      >小程序卡片</el-radio
    >
    <div style="margin-top: 10px">
      <template v-if="form.msgtype === 'text'">
        <el-input
          type="textarea"
          @blur="handleBlur"
          style="width: 500px"
          :autosize="{ minRows: 13 }"
          placeholder="请输入内容"
          v-model="form.textStr"
        >
        </el-input>
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <el-button
            class="button-mini"
            type="text"
            style="margin-right: 10px"
            @click="(showTitleDialog = true), (insertInfoType = 1)"
            >插入标题</el-button
          >
          <insert-book
            :channel="accountChannel"
            @success="handleSelectSuccess"
          />
          <insert-page
            :channel="accountChannel"
            @success="handleSelectSuccess"
          />
          <el-button
            class="button-mini"
            type="text"
            @click="handleInsert('{$nickname}')"
            style="margin-right: 10px"
            >插入用户昵称</el-button
          >
          <insert-activity
            :channel="accountChannel"
            :type="form.msgtype"
            @success="handleActivityInsert"
          />
          <el-button
            class="button-mini"
            type="text"
            @click="showMiniDialog = true"
            >插入小程序</el-button
          >
          <el-button
            class="button-mini"
            type="text"
            @click="showTemplate = true"
            >插入模板</el-button
          >
          <el-button
            v-if="[0, 1, 3, 6, 7].includes(accountChannel.platform)"
            class="button-mini"
            type="text"
            @click="showBookCoin = true"
            >插入书币</el-button
          >
        </div>
      </template>
      <el-form v-if="form.msgtype === 'news'" label-width="40px">
        <el-form-item label="链接">
          <span
            class="_novelName"
            style="margin-right: 10px"
            ref="_novelName"
            >{{ form.novelName }}</span
          ><br />
          <insert-book
            :channel="accountChannel"
            @success="handleSelectSuccess"
          />
          <insert-page
            :channel="accountChannel"
            @success="handleSelectSuccess"
          />
          <insert-activity
            :channel="accountChannel"
            :type="form.msgtype"
            @success="handleActivityInsert"
          />
          <el-button
            v-if="[0, 1, 3, 6, 7].includes(accountChannel.platform)"
            class="button-mini"
            type="text"
            @click="showBookCoin = true"
            >插入书币</el-button
          >
        </el-form-item>
        <el-form-item label="标题">
          <el-input
            v-model="form.title"
            type="textarea"
            style="width: 400px; margin-right: 10px"
          />
          <el-button
            type="text"
            @click="(showTitleDialog = true), (insertInfoType = 2)"
            >选择标题</el-button
          >
        </el-form-item>
        <el-form-item label="图片">
          <img
            v-if="form.picurl"
            :src="form.picurl"
            style="width: 320px; height: 160px; margin-right: 10px"
          />
          <el-button type="text" @click="showPicDialog = true"
            >选择图片</el-button
          >
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            type="textarea"
            v-model="form.description"
            style="width: 400px; margin-right: 10px"
          />
          <el-button type="text" @click="showDescDialog = true"
            >选择描述</el-button
          >
        </el-form-item>
        <el-form-item>
          <div class="example">
            <div class="card">
              <p class="mesg-title">{{ form.title || "消息标题" }}</p>
              <div class="detail">
                <p>{{ form.description || "描述文字" }}</p>
                <template>
                  <img :src="form.picurl" alt="" v-if="form.picurl" />
                  <div class="image-slot" v-else>
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div v-if="form.msgtype === 'voice'">
        <span>{{
          form.selectVoice
            ? form.selectVoice.title || form.selectVoice.mediaId
            : ""
        }}</span>
        <el-button type="text" @click="showVoiceDialog = true"
          >选择音频素材</el-button
        >
      </div>
      <div v-if="form.msgtype === 'image'">
        <!-- <span>{{
          form.selectImage
            ? form.selectImage.title || form.selectImage.mediaId
            : ""
        }}</span> -->
        <div
          class="image-text-box"
          @click="showImageDialog = true"
          style="margin-right: 10px"
        >
          <div class="material-item" v-if="form.selectImage">
            <p>{{ form.selectImage.title || form.selectImage.mediaId }}</p>
            <img :src="form.selectImage.url" alt="" />
          </div>
          <p v-if="!form.selectImage"><i class="el-icon-plus"></i></p>
        </div>
        <!-- <el-button type="text" @click="showImageDialog = true"
          >选择图片素材</el-button
        > -->
      </div>
      <el-form
        label-position="left"
        label-width="120px"
        class="form-container"
        v-if="form.msgtype === 'miniprogrampage'"
      >
        <input
          type="file"
          ref="uploadFile"
          style="display: none"
          accept="image/*"
          @change="handleFileChange"
        />
        <el-form-item
          v-loading="miniprogramLoading"
          label="小程序封面"
          class="necessary"
        >
          <div class="selectMiniPicContainer">
            <div class="changeMiniPicType">
              <el-radio v-model="selectMiniPicType" :label="1"
                >选择素材</el-radio
              >
              <el-radio
                style="margin-left: 0"
                v-model="selectMiniPicType"
                :label="2"
                >本地上传</el-radio
              >
            </div>
            <div class="selectMiniPicBox" v-loading="selectMiniImageLoading">
              <div
                @click="handleChangeMiniPic"
                v-if="!form.picurl"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 30px;
                  width: 200px;
                  height: 100px;
                  margin-right: 10px;
                  border: 1px solid #dcdfe6;
                  border-radius: 4px;
                  cursor: pointer;
                "
              >
                <div><i class="el-icon-plus"></i></div>
              </div>
              <img
                @click="handleChangeMiniPic"
                v-if="form.picurl"
                :src="form.picurl"
                style="
                  width: 200px;
                  height: 100px;
                  margin-right: 10px;
                  cursor: pointer;
                "
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item label="标题" class="necessary">
          <el-input
            type="text"
            v-model="form.title"
            style="width: 300px; margin-right: 10px"
          />
        </el-form-item>
        <el-form-item label="appid" class="necessary">
          <el-input
            type="text"
            v-model="form.appid"
            style="width: 300px; margin-right: 10px"
          />
        </el-form-item>
        <el-form-item label="页面地址" class="necessary">
          <el-input
            type="text"
            v-model="form.pagepath"
            style="width: 300px; margin-right: 10px"
          />
        </el-form-item>
      </el-form>
    </div>

    <select-title
      :show="showTitleDialog"
      :classifyList="classifyList"
      @close="showTitleDialog = false"
      @success="handleInsertTitle"
    />
    <!-- @success="(url) => (form.picurl = url)" -->
    <select-image
      :show="showPicDialog"
      :classifyList="classifyList"
      @close="showPicDialog = false"
      @success="handleSelectImageSuccess"
    />

    <select-description
      :show="showDescDialog"
      :classify-list="classifyList"
      @close="showDescDialog = false"
      @success="(description) => (form.description = description)"
    />
    <el-dialog
      title="插入小程序"
      width="500px"
      :visible.sync="showMiniDialog"
      :append-to-body="true"
    >
      <insert-mini-program
        :show="showMiniDialog"
        @close="showMiniDialog = false"
        @success="handleInsert"
      />
    </el-dialog>

    <InsertTemplate
      :classifyList="classifyList"
      :show="showTemplate"
      @close="showTemplate = false"
      @handleOk="handleInsertTemplate"
    ></InsertTemplate>

    <el-dialog
      title="选择音频素材"
      width="800px"
      :visible.sync="showVoiceDialog"
      :append-to-body="true"
    >
      <select-material
        :show="showVoiceDialog"
        :type="form.msgtype"
        @close="showVoiceDialog = false"
        :account-id="accountChannel ? accountChannel.id : null"
        @success="(voice) => (form.selectVoice = voice)"
      />
    </el-dialog>
    <el-dialog
      title="选择图片素材"
      width="800px"
      :visible.sync="showImageDialog"
      :append-to-body="true"
    >
      <s-image
        :show="showImageDialog"
        :type="form.msgtype"
        @close="showImageDialog = false"
        :account-id="accountChannel ? accountChannel.id : null"
        @success="(image) => (form.selectImage = image)"
      />
    </el-dialog>
    <span class="delete-btn" @click="$emit('delete')">删除</span>
    <insert-book-coin
      :show="showBookCoin"
      :channel="accountChannel"
      :type="form.msgtype"
      @close="showBookCoin = false"
      @success="handleBookCoinInsert"
    ></insert-book-coin>
  </div>
</template>

<script>
/**
 * form {
 *   msgtype:消息类型
 *   textStr  文本消息内容
 *  novelName: 小说名称
 *  url：连接
 *  title: 标题
 *  picurl 图片
 *  description 描述
 * }
 */
import InsertBook from "../../service/insertBook/index";
import InsertPage from "../../service/insertPage/index";
import SelectTitle from "../../service/components/selectTitle";
import SelectImage from "../../service/components/selectPic";
import InsertTemplate from "../../service/components/insertTemplate";
import SelectDescription from "../../service/components/selectDescription";
import InsertActivity from "../../service/insertActivity/index";
import InsertMiniProgram from "../../service/components/insertMiniProgram";
import selectMaterial from "../../service/components/selectMaterial";
import sImage from "../../service/components/selectImage";
import { getClassifyList } from "../../../api/classify";
import InsertBookCoin from "./../../service/insertBookCoin/index.vue";
import { platformLocalUpload, platformLocalSelectUpload } from "@/api/service";

export default {
  name: "editContent",
  props: {
    form: Object,
    accountChannel: Object,
    active: Boolean,
    remark: String,
  },
  data() {
    return {
      showMiniDialog: false,
      showTitleDialog: false,
      showPicDialog: false,
      showDescDialog: false,
      showVoiceDialog: false,
      showImageDialog: false,
      textIndex: 0,
      showTemplate: false,
      classifyList: [],
      showLinkInfo: false,
      insertInfoType: 1,
      showBookCoin: false,
      miniprogramLoading: false,
      // 小程序卡片选择改版
      isSelectMiniImage: false,
      selectMiniImageLoading: false,
      selectMiniPicType: 1,
    };
  },
  watch: {
    form: function (newV) {
      if (newV) {
      }
    },
  },
  mounted() {
    getClassifyList({ type: 1, status: 1 }).then((res) => {
      this.classifyList = res;
    });
  },
  methods: {
    handleSelectImageSuccess(data) {
      // isSelectMiniImage判定是小程序卡片调用还是其他调用
      if (!this.isSelectMiniImage) {
        this.form.picurl = data;
      } else {
        this.form.picurl = data;
        this.selectMiniImageLoading = true;
        platformLocalSelectUpload({
          accountChannelId: this.accountChannel.id,
          url: data,
          type: "thumb",
        })
          .then((res) => {
            this.form.thumb_media_id = res.media_id;
          })
          .finally(() => {
            this.selectMiniImageLoading = false;
            this.isSelectMiniImage = false;
          });
      }
    },
    handleChangeMiniPic() {
      if (!this.accountChannel || !this.accountChannel.id) {
        return this.$message.error("请先选择公众号");
      }
      if (this.selectMiniPicType == 2) {
        this.$refs.uploadFile.click();
      } else {
        this.showPicDialog = true;
        this.isSelectMiniImage = true;
      }
    },
    handleFileChange(e) {
      const targetFile = e.target.files[0];
      const file = new FormData();
      file.append("file", targetFile);
      // return;
      this.miniprogramLoading = true;
      platformLocalUpload(this.accountChannel.id, "thumb", file)
        .then((res) => {
          this.$set(this.form, "picurl", res.url);
          this.$set(this.form, "thumb_media_id", res.media_id);
        })
        .finally(() => {
          this.miniprogramLoading = false;
        });
    },
    handleInsertTemplate(val) {
      const { content } = val;
      this.handleInsert(content);
    },
    handleInsertTitle(title) {
      this.insertInfoType === 1
        ? this.handleInsert(title)
        : (this.form.title = title);
    },
    handleActivityInsert(data) {
      if (this.form.msgtype === "text") {
        this.handleInsert(data.join("\n"));
      } else {
        this.form.novelName = data.name;
        this.form.url = data.url;
        this.showLinkInfo = true;
        if (this.$refs._novelName) {
          this.$refs._novelName.innerText = data.name;
        }
      }
    },
    handleBookCoinInsert(data) {
      if (this.form.msgtype === "text") {
        this.handleInsert(data.value);
      } else {
        this.form.novelName = data.name;
        this.form.url = data.url;
        this.showLinkInfo = true;
        if (this.$refs._novelName) {
          this.$refs._novelName.innerText = data.name;
        }
      }
    },
    handleSelectSuccess({ link, name, url }) {
      if (this.form.msgtype === "text") {
        this.handleInsert(link);
      } else {
        this.form.novelName = name;
        this.form.url = url;
        this.showLinkInfo = true;
        if (this.$refs._novelName) {
          this.$refs._novelName.innerText = name;
        }
      }
    },
    handleInsert(str) {
      const { textIndex } = this;
      const { textStr } = this.form;
      this.form.textStr =
        textStr.slice(0, textIndex) + str + textStr.slice(textIndex);
    },
    handleBlur(event) {
      this.textIndex = event.srcElement.selectionStart;
    },
  },
  components: {
    InsertBook,
    InsertPage,
    SelectTitle,
    SelectImage,
    SelectDescription,
    InsertActivity,
    InsertMiniProgram,
    InsertTemplate,
    selectMaterial,
    sImage,
    InsertBookCoin,
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-drawer__body {
  overflow-y: auto;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.content-cont {
  width: 600px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ececec;
  position: relative;
  margin-bottom: 10px;
}

.delete-btn {
  position: absolute;
  color: #f56c6c;
  cursor: pointer;
  right: 20px;
  top: 20px;
}

.example {
  width: 375px;
  background-color: #f1f1f1;
  padding: 10px;
}

.card {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mesg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: bold;
  line-height: 20px;
}

.detail {
  display: flex;
  align-items: center;
}

.detail p {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.detail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}
// 图片选择
.image-text-box {
  cursor: pointer;
  display: block;
  width: 500px;
  height: 260px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px 15px;
  background-color: #fff;
  color: #8b8b8b;
  line-height: 16px;
  position: relative;
  > p {
    display: inline-block;
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    text-align: center;
    color: #ccc;
  }
  .material-item {
    width: 300px;
    p {
      padding: 5px;
    }
    img {
      max-width: 200px;
      max-height: 200px;
    }
  }
}
// 小程序图片选择
.selectMiniPicContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .changeMiniPicType {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;
    label {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
}
</style>
