<template>
  <el-dialog
    :title="`${form.id ? '编辑' : '新增'}小时群发消息`"
    :visible.sync="show"
    :fullscreen="true"
    center
    :before-close="handleClose"
  >
    <el-row :gutter="20">
      <el-form
        label-position="left"
        label-width="120px"
        class="form-container"
        v-loading="formLoading"
      >
        <!-- 表单顶部-->
        <div class="form-top">
          <el-form-item label="公众号：">
            <el-select
              style="width: 100%"
              v-model="selectChannel"
              value-key="id"
              filterable
              placeholder="公众号"
              :disabled="!!info"
            >
              <el-option
                v-for="item in account"
                :key="item.id"
                :value="item"
                :label="item.channelName"
              >
                <span style="float: left">{{ item.channelName }}</span>
                <span style="float: right; margin-left: 15px; font-size: 13px"
                  >{{ item.platformStr }} - {{ item.uname }}</span
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="form.remark" placeholder="请填写备注"></el-input>
          </el-form-item>
        </div>
        <!-- 表单中部 -->
        <div class="form-body">
          <div class="form-body-left">
            <el-form-item label="发送分钟：">
              <el-input-number
                v-model="form.hours"
                :min="0"
                :max="2880"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="发送消息：" v-show="selectChannel">
              <el-button
                icon="el-icon-plus"
                style="margin-bottom: 10px"
                type="primary"
                @click="messageList.unshift({ msgtype: 'text', textStr: '' })"
                >新增
              </el-button>
              <content-edit
                v-for="(item, index) in messageList"
                :key="index"
                :form="item"
                :remark="form.mark"
                :accountChannel="selectChannel"
                :active="activeIndex === index"
                @check="activeIndex = index"
                @delete="handleDelete(index)"
              />
            </el-form-item>
            <!-- 新增用户标签 -->
            <el-form-item label="接收用户" style="margin-bottom: 14px">
              <el-radio-group v-model="form.sendAll" size="medium">
                <el-radio-button :label="1">全部用户</el-radio-button>
                <el-radio-button :label="0">标签用户</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="测试人">
              <!--        <el-input v-model="testId" style="width: 350px;margin-right: 10px"/>-->
              <el-select
                v-model="testId"
                filterable
                remote
                reserve-keyword
                placeholder="请输入用户名"
                :remote-method="userQuery"
                :loading="remoteLoading"
              >
                <el-option
                  v-for="item in testUser"
                  :key="item.openId"
                  :label="item.nickname"
                  :value="item.openId"
                >
                  <div class="row">
                    <user-avatar :info="item" name-prop="nickname" :size="25" />
                    <span style="margin-left: 10px">{{ item.nickname }}</span>
                  </div>
                </el-option>
              </el-select>
              <el-button
                style="margin-left: 10px"
                type="primary"
                @click="handleTest"
                >发送</el-button
              >
            </el-form-item>
          </div>
          <div class="form-body-right">
            <el-form label-width="120px">
              <el-form label-width="85px">
                <!-- 基础属性新增 -->
                <!-- 基础属性新增结束 -->
                <el-form-item label="充值情况">
                  <el-radio-group v-model="form.rechargeType" size="medium">
                    <el-radio-button :label="0">不限</el-radio-button>
                    <el-radio-button :label="1">未充值</el-radio-button>
                    <el-radio-button :label="2">已充值</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <div class="inner-form" v-if="form.rechargeType === 2">
                  <el-form-item label="充值间隔" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.rechargeTimeSpaceStart"
                      :step-strictly="true"
                      :step="1"
                      placeholder="开始天数"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.rechargeTimeSpaceEnd"
                      :step-strictly="true"
                      :step="1"
                      placeholder="结束天数"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <!-- 阳光书城 掌中云天机剩余书币 -->
                  <el-form-item
                    label="剩余书币"
                    v-if="
                      form.rechargeType === 2 &&
                      (selectChannel.platform === 3 ||
                        selectChannel.platform === 0)
                    "
                  >
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minKanDian"
                      placeholder="最小剩余书币"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxKanDian"
                      placeholder="最大剩余书币"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="书币消耗率"
                    v-if="
                      form.rechargeType === 2 &&
                      [0, 3].includes(selectChannel.platform)
                    "
                  >
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minKanDianRate"
                      placeholder="最小书币消耗率"
                      :precision="2"
                      :min="0"
                      :step="1"
                    >
                    </el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxKanDianRate"
                      placeholder="最大书币消耗率"
                      :precision="2"
                      :min="0"
                      :step="1"
                    >
                    </el-input-number>
                  </el-form-item>
                  <el-form-item label="累计充值" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minTotalRecharge"
                      placeholder="最小充值"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      placeholder="最大充值"
                      v-model="form.maxTotalRecharge"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="最后充值" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minLastRechargeAmt"
                      placeholder="最小充值"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      placeholder="最大充值"
                      v-model="form.maxLastRechargeAmt"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="充值次数" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minRechargeCount"
                      placeholder="最小次数"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxRechargeCount"
                      placeholder="最大次数"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>

                  <!-- 统计属性 -->
                  <!-- 最近7日价值 -->
                  <el-form-item
                    label="近7日充值"
                    v-if="form.rechargeType === 2"
                  >
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minSevenRecharge"
                      placeholder="最小充值"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxSevenRecharge"
                      placeholder="最大充值"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>

                  <div class="vip-consume">
                    <!-- 是否vip -->
                    <el-form-item
                      style="width: 220px"
                      label="VIP"
                      label-width="45px"
                      v-if="form.rechargeType === 2"
                    >
                      <el-radio-group v-model="form.isVip">
                        <el-radio :label="2">不限</el-radio>
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <!-- 连续三周均有充值 -->

                    <div class="consume-box" style="width: 60%">
                      <el-tooltip
                        style="margin-left: 20px"
                        class="item"
                        effect="dark"
                        content="用户最近三周内每周均有充值"
                        placement="left"
                      >
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                      <el-form-item
                        label-width="80px"
                        label="连续付费"
                        v-if="form.rechargeType === 2"
                      >
                        <el-radio-group v-model="form.continuousRecharge">
                          <el-radio :label="0">不限</el-radio>
                          <el-radio :label="1">是</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- rfm -->
                  <div class="rfm-box">
                    <el-popover placement="right" width="500" trigger="hover">
                      <el-table :data="gridData" height="300">
                        <el-table-column
                          width="120"
                          property="label"
                          label="客户标签"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="R"
                          label="R"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="F"
                          label="F"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="M"
                          label="M"
                        ></el-table-column>
                        <el-table-column
                          property="scene"
                          label="场景"
                        ></el-table-column>
                        <el-table-column
                          width="50"
                          property="level"
                          label="等级"
                        ></el-table-column>
                      </el-table>
                      <i
                        slot="reference"
                        style="margin-left: 20px"
                        class="el-icon-question"
                      ></i>
                    </el-popover>
                    <el-form-item
                      label="RFM"
                      label-width="60px"
                      v-if="form.rechargeType === 2"
                    >
                      <el-select
                        v-model="form.rfm"
                        style="width: 350px"
                        placeholder="请选择rfm"
                        multiple
                        clearable
                      >
                        <el-option label="A类" :value="1"></el-option>
                        <el-option label="B类" :value="2"></el-option>
                        <el-option label="C类" :value="3"></el-option>
                      </el-select>
                      <!-- 解释表格 -->
                    </el-form-item>
                  </div>
                  <!-- 剩余优惠券 -->
                  <el-form-item
                    label="优惠敏感度"
                    class="add-element"
                    v-if="form.rechargeType === 2"
                  >
                    <el-select
                      style="width: 350px"
                      v-model="form.couponLevel"
                      placeholder="请选择优惠券级别"
                    >
                      <el-option label="不限" :value="0"> </el-option>
                      <el-option label="1级" :value="1"> </el-option>
                      <el-option label="2级" :value="2"> </el-option>
                      <el-option label="3级" :value="3"> </el-option>
                      <el-option label="4级" :value="4"> </el-option>
                      <el-option label="5级" :value="5"> </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="书籍" v-if="form.rechargeType === 2">
                    <el-select
                      v-model="form.bookId"
                      filterable
                      remote
                      style="width: 350px"
                      reserve-keyword
                      clearable
                      placeholder="请输入书籍名称"
                      :remote-method="getBooks"
                    >
                      <el-option
                        v-if="defaultBook"
                        :label="defaultBook.bookName"
                        :value="defaultBook.id"
                      ></el-option>
                      <el-option
                        v-for="item in bookList"
                        :key="item.id"
                        :label="item.bookName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-form>
              <template>
                <el-form-item label-width="90px" label="性别">
                  <el-radio-group v-model="form.sex">
                    <el-radio :label="0">不限</el-radio>
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="所在地区" label-width="85px">
                  <el-select
                    size="small"
                    style="width: 100px"
                    v-model="form.province"
                    filterable
                    placeholder="省份"
                    clearable
                  >
                    <el-option
                      v-for="item in area"
                      :key="item.cid"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    size="small"
                    style="width: 100px"
                    v-model="form.city"
                    filterable
                    placeholder="城市"
                    clearable
                  >
                    <el-option
                      v-for="item in areaCity"
                      :key="item.cid"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="关注时间" label-width="85px">
                  <el-date-picker
                    v-model="form.startDate"
                    align="right"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 180px"
                    placeholder="开始时间"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                  <el-date-picker
                    v-model="form.endDate"
                    align="right"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 180px; margin-left: 10px"
                    placeholder="结束时间"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="签到次数" label-width="85px">
                  <el-input-number
                    style="width: 165px"
                    v-model="form.minSignInCount"
                    placeholder="最小签到次数"
                    :min="0"
                    :step="1"
                  >
                  </el-input-number>
                  <span style="margin: 0 10px"></span>

                  <el-input-number
                    style="width: 165px"
                    v-model="form.maxSignInCount"
                    placeholder="最大签到次数"
                    :min="0"
                    :step="1"
                  >
                  </el-input-number>
                </el-form-item>
              </template>
            </el-form>
            <div class="form-body-right-mask" v-if="form.sendAll !== 0">
              <p>仅标签用户可操作</p>
            </div>
          </div>
        </div>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button
        style="margin-left: 10px"
        type="primary"
        @click="handleSubmit"
        :loading="submitLoading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  editHourMessage,
  testSend,
  getHourMessageDetail,
  getAccountUser,
} from "../../../api/service";
import ContentEdit from "./editContent";
import UserAvatar from "../../../components/UserAvatar";
import { messagePickerOptions } from "@/assets/js/options";
import area from "@/assets/js/area.js";
import { getBookList } from "@/api/account";
const defaultForm = {
  msgType: "text",
  remark: "",
  hours: 0,
  sendAll: 1,
  rechargeType: 2,
  isVip: 2,
  continuousRecharge: 0,
  sex: 0,
};
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Array,
      default: () => [],
    },
    info: Object,
    accountId: Number,
  },
  data() {
    return {
      testId: null,
      form: {},
      pickerOptions: messagePickerOptions,
      formLoading: false,
      submitLoading: false,
      activeIndex: 0,
      messageList: [],
      selectChannel: null,
      remoteLoading: false,
      selectVoice: null,
      selectImage: null,
      testUser: [],
      gridData: [
        {
          label: "重要价值客户",
          R: "高",
          F: "高",
          M: "高",
          scene: "最近充值-高频次-高金额",
          level: "A类",
        },
        {
          label: "重要深耕客户",
          R: "高",
          F: "低",
          M: "高",
          scene: "最近充值-低频次-高金额",
          level: "A类",
        },
        {
          label: "重要唤回客户",
          R: "低",
          F: "高",
          M: "高",
          scene: "最近未充-高频次-高金额",
          level: "B类",
        },
        {
          label: "重要挽留客户",
          R: "低",
          F: "低",
          M: "高",
          scene: "最近未充-低频次-高金额",
          level: "B类",
        },
        {
          label: "潜力客户",
          R: "高",
          F: "高",
          M: "低",
          scene: "最近充值-高频次-低金额",
          level: "B类",
        },
        {
          label: "一般发展客户",
          R: "高",
          F: "低",
          M: "低",
          scene: "最近充值-低频次-低金额",
          level: "B类",
        },
        {
          label: "一般维持客户",
          R: "低",
          F: "高",
          M: "低",
          scene: "最近未充-高频次-低金额",
          level: "B类",
        },
        {
          label: "流失客户",
          R: "低",
          F: "低",
          M: "低",
          scene: "最近未充-低频次-低金额",
          level: "C类",
        },
      ],
      defaultBook: null,
      bookList: [],
      area,
      areaCity: [],
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.userQuery();
        if (this.info) {
          this.initData();
        } else {
          this.form = { ...defaultForm };
          this.messageList = [
            {
              msgtype: "text",
              textStr: "",
              selectVoice: null,
              selectImage: null,
            },
          ];
          const defaultAccount = this.account.find(
            (item) => item.id === this.accountId
          );
          this.selectVoice = null;
          this.selectImage = null;
          this.selectChannel = {
            id: this.accountId,
            channelName: defaultAccount ? defaultAccount.channelName : "",
            platform: defaultAccount ? defaultAccount.platform : null,
          };
        }
      }
    },
    "form.province": {
      handler(newValue, oldValue) {
        this.area.forEach((item) => {
          if (item.name === this.form.province) {
            this.areaCity = item.children;
          }
        });
        if (newValue === "") {
          this.areaCity = [];
        }
        if (oldValue !== undefined) {
          this.$set(this.form, "city", null);
        }
      },
    },
  },
  methods: {
    getBooks(query) {
      this.defaultBook = null;
      getBookList({ page: 1, pageSize: 50, bookName: query }).then((res) => {
        this.bookList = res.list;
      });
    },
    handleDelete(index) {
      if (this.messageList.length <= 1) {
        this.$message.warning("发送消息至少一条");
      } else {
        this.messageList.splice(index, 1);
      }
    },
    userQuery(name) {
      this.remoteLoading = true;
      getAccountUser(this.accountId, name)
        .then((res) => {
          this.testUser = res;
        })
        .finally(() => {
          this.remoteLoading = false;
        });
    },
    initData() {
      this.formLoading = true;
      getHourMessageDetail(this.info.id)
        .then((res) => {
          const { fixedContentsList, accountChannelId, channelName, ...other } =
            res;
          this.form = other;
          const defaultAccount = this.account.find(
            (item) => item.id === accountChannelId
          );
          this.selectChannel = {
            id: accountChannelId,
            channelName: channelName,
            platform: defaultAccount ? defaultAccount.platform : null,
          };
          this.messageList = fixedContentsList.map((item) => {
            const { msgtype, content, novelName } = item;
            let data = {};
            const contentObj = JSON.parse(content);
            if (msgtype === "text") {
              data.textStr = contentObj.content;
            } else if (msgtype === "news") {
              data = { ...contentObj.articles[0], novelName };
            } else if (msgtype === "voice") {
              //  data = {title:novelName,media_id:contentObj.media_id}

              data = {
                selectVoice: {
                  title: novelName,
                  mediaId: contentObj.media_id,
                },
              };
            } else if (msgtype === "image") {
              data = {
                selectImage: {
                  mediaId: contentObj.media_id,
                  title: novelName.includes("{")
                    ? JSON.parse(novelName).title
                    : novelName,
                  url: novelName.includes("{")
                    ? JSON.parse(novelName).url
                    : null,
                },
              };
              // data = {title:novelName,media_id:contentObj.media_id}
            } else if (msgtype === "miniprogrampage") {
              data = {
                ...contentObj,
                picurl: novelName,
                novelName,
              };
              // data = {title:novelName,media_id:contentObj.media_id}
            }
            data.msgtype = msgtype;
            return data;
          });
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    // for测试
    handleTesttest() {
      const numArr = [1, 2, 3, 4];
      const getNum = (num) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(num);
          }, 1000);
        });
      };
      const forLoop = async () => {
        for (let i = 0; i < numArr.length; i++) {
          const num = await getNum(numArr[i]);
        }
      };
      forLoop();
    },

    // 封装测试接口请求promise以顺序循环使用
    fnTestSend(params) {
      return new Promise((resolve, reject) => {
        // setTimeout(() => {
        //   resolve(params.content);
        // }, 1000);
        testSend(params).then((res) => {
          resolve(res);
        });
      });
    },
    // 新增所有消息全部发送给测试人
    async handleTest() {
      for (
        let indexTest = 0;
        indexTest < this.messageList.length;
        indexTest++
      ) {
        const { remark } = this.form;
        const {
          textStr,
          msgtype,
          picurl,
          url,
          description,
          title,
          selectVoice,
          selectImage,
        } = this.messageList[indexTest];
        if (!this.selectChannel) {
          this.$message.error("请选择公众号");
          return false;
        }
        if (msgtype === "text" && !textStr) {
          this.$message.error("请输入发送内容");
          return false;
        }
        if (msgtype === "news" && !url) {
          this.$message.error("请选择图文链接");
          return false;
        }
        if (msgtype === "voice" && !selectVoice) {
          this.$message.error("请选择音频资源");
          return false;
        }
        if (msgtype === "image" && !selectImage) {
          this.$message.error("请选择图片资源");
          return false;
        }
        if (!this.testId) {
          this.$message.error("请输入测试openid");
          return false;
        }
        if (!remark) {
          this.$message.error("请输入备注");
          return false;
        }
        const params = {
          accountChannelId: this.selectChannel.id,
          msgType: msgtype,
          openId: this.testId,
          remark,
        };
        if (msgtype === "text") {
          params.content = JSON.stringify({ content: textStr });
        } else if (msgtype === "news") {
          params.content = JSON.stringify({
            articles: [{ picurl, url, description, title }],
          });
        } else if (msgtype === "voice") {
          params.content = JSON.stringify({
            media_id: selectVoice.mediaId,
          });
        } else if (msgtype === "image") {
          params.content = JSON.stringify({
            media_id: selectImage.mediaId,
          });
        } else if (msgtype === "miniprogrampage") {
          params.content = JSON.stringify({
            title: this.messageList[indexTest].title,
            appid: this.messageList[indexTest].appid,
            pagepath: this.messageList[indexTest].pagepath,
            thumb_media_id: this.messageList[indexTest].thumb_media_id,
          });
          params.novelName = this.messageList[indexTest].picurl;
        }
        // console.log(params, "params");
        // return;
        // 推进promiseall中顺序执行
        await this.fnTestSend(params);
        if (indexTest === this.messageList.length - 1) {
          this.$message.success("发送成功");
        }
      }
    },
    handleSubmit() {
      const { form, messageList, info } = this;
      if (!this.selectChannel) {
        this.$message.error("请选择公众号");
        return false;
      }
      const params = { ...form, accountChannelId: this.selectChannel.id };
      // console.log(params, "params");
      // return;
      params.fixedContentsList = messageList.map((item) => {
        const { msgtype, textStr, selectVoice, selectImage } = item;
        let novelName = "";
        let content = "";
        if (msgtype === "voice" && !selectVoice) {
          this.$message.error("请选择音频资源");
          return false;
        }
        if (msgtype === "image" && !selectImage) {
          this.$message.error("请选择图片资源");
          return false;
        }
        // 小程序卡片
        if (item.msgtype === "miniprogrampage") {
          if (!item.picurl) {
            this.$message.error("请选择小程序封面");
            return false;
          }
          if (!item.title) {
            this.$message.error("请填写标题");
            return false;
          }
          if (!item.appid) {
            this.$message.error("请填写appid");
            return false;
          }
          if (!item.pagepath) {
            this.$message.error("请填写页面地址");
            return false;
          }
        }
        if (msgtype === "text") {
          content = JSON.stringify({ content: textStr });
        } else if (msgtype === "news") {
          novelName = item.novelName;
          content = JSON.stringify({
            articles: [
              {
                picurl: item.picurl,
                url: item.url,
                description: item.description,
                title: item.title,
              },
            ],
          });
        } else if (msgtype === "voice") {
          content = JSON.stringify({
            media_id: selectVoice.mediaId,
          });
          novelName = selectVoice.title;
        } else if (msgtype === "image") {
          content = JSON.stringify({
            media_id: selectImage.mediaId,
          });
          novelName = JSON.stringify(selectImage);
        } else if (msgtype === "miniprogrampage") {
          content = JSON.stringify({
            title: item.title,
            appid: item.appid,
            pagepath: item.pagepath,
            thumb_media_id: item.thumb_media_id,
          });
          novelName = item.picurl;
        }
        return { msgtype, content, novelName };
      });
      // return false;
      if (info) params.id = info.id;
      // return;
      this.submitLoading = true;
      editHourMessage(params)
        .then(() => {
          this.$message.success("保存成功");
          this.handleClose();
          this.$emit("refresh");
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    handleClose() {
      this.$emit("close");
    },
  },
  components: {
    ContentEdit,
    UserAvatar,
  },
  mounted() {
    getBookList({ page: 1, pageSize: 50 }).then((res) => {
      this.bookList = res.list;
    });
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 700px;
  margin: auto;
}
/deep/ .el-button {
  margin-right: 0px !important;
  margin-left: 0px;
}
.form-container {
  width: 1400px;
  margin: auto;
}
.inner-form {
  width: 480px;
  border: 1px solid #f6f6f6;
  border-radius: 20px;
  padding: 10px;
  background-color: #f6f6f6;
  margin-left: -10px;
}
.form-container /deep/ .el-form-item__label {
  font-size: 13px;
}
.form-container /deep/ .el-radio-button--medium .el-radio-button__inner {
  font-size: 13px;
}
.form-container .el-radio__label {
  font-size: 13px;
}
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}
.el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
.form-top {
  background-color: #fbfbfb;
  padding: 10px 20px 0 20px;
}
.form-body {
  background-color: #fbfbfb;
  margin-top: 10px;
  padding: 0 20px;
  padding-bottom: 0;
  display: flex;
  .message-box {
    justify-content: flex-start;
  }
  .form-body-left {
    border-right: 1px dashed #d9d9d9;
  }
  .form-body-left,
  .form-body-right {
    padding-top: 10px;
    width: 730px;
  }
  .form-body-right {
    padding-left: 85px;
    position: relative;
    .form-body-right-mask {
      z-index: 9999;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#ababab, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 16px;
        color: #fff;
      }
    }
    .vip-consume {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-radio-group {
        .el-radio {
          margin: 0 5px !important;
        }
      }
      .consume-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        i {
          margin: 0 10px 10px 0;
        }
      }
    }
    .rfm-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      i {
        margin: 0 10px 10px 0 !important;
      }
    }
  }
}
</style>
