<template>
  <div>
    <div
      class="part border-radius-6 margin-top-bottom-twenty box-shadow-light-grey padding-20"
    >
      <div class="flex-between operation padding-bottom-20">
        <div>
          <el-button @click="getList">刷新数据</el-button>
          <el-button
            class="custom-button-margin-left"
            type="success"
            @click="isCopy = true"
            :disabled="selected.length === 0"
            >批量复制消息
          </el-button>
          <el-input
            class="margin-left-twentyFour"
            v-model="copyRelationId"
            style="width: 200px"
            placeholder="请输入消息ID"
            clearable
            @clear="handleClear"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList()"
            ></el-button
          ></el-input>
        </div>
        <el-button
          type="primary"
          @click="handleDialogShow(null, 'isEdit')"
          icon="el-icon-plus"
          >新增小时群发</el-button
        >
      </div>
      <el-table
        v-loading="loading"
        :data="tableData"
        height="calc(100vh - 320px)"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" width="80" label="ID"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="hours" label="发送分钟"> </el-table-column>
        <el-table-column prop="status" label="启用状态">
          <template slot-scope="scope">
            <el-switch
              @change="handleSwitchChange(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              style="margin-right: 5px"
              :inactive-value="0"
              active-color="#00bf8a"
              inactive-color="#C0CCDA"
            >
            </el-switch>
            <!-- <span>{{ scope.row.status === 0 ? "关闭" : "开启" }}</span> -->
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间"> </el-table-column>
        <el-table-column prop="action" width="300" label="操作">
          <template slot-scope="scope">
            <el-button
              class="button-small"
              type="info"
              @click="handleDialogShow(scope.row, 'showDetail')"
              >详情</el-button
            >
            <el-button
              class="button-small"
              type="primary"
              @click="handleDialogShow(scope.row, 'isEdit')"
              >编辑</el-button
            >
            <el-button
              class="button-small"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <edit
      :show="isEdit"
      :info="current"
      :account-id="accountChannelId"
      @close="isEdit = false"
      :account="channelList"
      @refresh="handlePageChange(1)"
    />
    <detail :show="showDetail" :info="current" @close="showDetail = false" />
    <el-dialog title="复制消息" center append-to-body :visible.sync="isCopy">
      <copy-message
        :show="isCopy"
        :accountList="channelList"
        :account-id="accountChannelId"
        :fixedIds="[...selected]"
        @close="isCopy = false"
        @refresh="handlePageChange(1)"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { messagePlatformList } from "@/assets/js/options"; //客服消息支持平台
import {
  getHourMessage,
  changeHourStatus,
  deleteHourMessage,
} from "../../../api/service";
import Edit from "./edit";
import Detail from "./detail";
import CopyMessage from "./copyHourMsg";

export default {
  name: "Service",
  props: ["accountChannelId", "relationId"],
  data() {
    return {
      loading: false,
      status: null,
      page: 1,
      total: 0,
      tableData: [],
      isEdit: false,
      isCopy: false,
      current: null,
      showDetail: false,
      selected: [],
      pageSize: 15,
      copyRelationId: null,
      messagePlatformList,
    };
  },
  computed: {
    channelList() {
      const { officialList } = this;
      return officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    ...mapGetters(["officialList"]),
  },
  beforeRouteEnter(to, form, next) {
    next((vm) => {
      vm.copyRelationId = vm.relationId;
      vm.getList();
    });
  },
  watch: {
    accountChannelId(newVal) {
      if (newVal) {
        this.handlePageChange(1);
      }
    },
  },
  methods: {
    handleDelete(item) {
      this.$confirm("此操作将永久删除该小时群发消息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteHourMessage(item.id).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        })
        .catch(() => {});
    },
    handleDialogShow(item, prop) {
      this[prop] = true;
      this.current = item;
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    handleSwitchChange(data) {
      const { status } = data;
      changeHourStatus(data.id, status)
        .then(() => {
          this.$message.success("设置成功");
          this.getList();
        })
        .catch(() => {
          data.status = status === 0 ? 1 : 0;
        });
    },
    handleSelectionChange(rows) {
      this.selected = rows.map((item) => item.id);
    },
    getList() {
      if (this.accountChannelId) {
        this.loading = true;
        getHourMessage(this.accountChannelId, {
          page: this.page,
          pageSize: this.copyRelationId ? 99999 : this.pageSize,
          id: this.copyRelationId ? this.copyRelationId : null,
        })
          .then((res) => {
            this.tableData = res.list;
            this.total = res.total;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleClear() {
      this.$emit("update:relationId", null);
      this.copyRelationId = null;
      this.page = 1;
      this.getList();
    },
  },
  components: {
    Edit,
    Detail,
    CopyMessage,
  },
};
</script>

<style lang="scss" scoped>
.operation {
  background: #fff;

}
.part {
  box-sizing: border-box;
  background: #fff;

}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
